<!-- Bact-45-->
<!-- Bact-51-->
<!-- BACT-FIX-DESIGN-1 @AUTH  karam mustafa -->

<template>
  <v-container>
    <v-row class="about-us">
      <v-col cols="12" md="6" class="mb-6">
        <h1 class="main-color mb-6">
          <b> {{ $t("form.contactUs") }} </b>
        </h1>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            :label="$t('form.name')"
            class="text-center"
            v-model="name"
            :rules="nameRules"
            required
          ></v-text-field>

          <v-text-field
            :label="$t('form.email')"
            class="text-center"
            v-model="email"
            :rules="emailRules"
            required
          ></v-text-field>
          <v-textarea
            name="input-7-1"
            :label="$t('form.text')"
            class="text-center"
            v-model="note"
            :rules="noteRules"
            hint="Hint text"
            required
          ></v-textarea>

          <div class="d-flex justify-center">
            <base-button
              @click="sendMsgMethod"
              :options="{ isLoading: loading }"
            >
              {{ $t("form.submit") }}
            </base-button>
            <base-button
              @click="clear"
              class="mx-2"
              :options="{ color: this.RColor }"
            >
              {{ $t("form.clear") }}
            </base-button>
          </div>
        </v-form>
      </v-col>
      <v-col cols="12" md="6" class="mt-6">
        <v-img src="@/assets/images/img-side.png" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseButton from "../Base/Buttons/BaseButton";
import { EventBus } from "../../main";
import { mapActions } from "vuex";

function notification(group, type, title, duration) {
  return EventBus.$notify({
    group: group,
    type: type,
    title: title,
    duration: duration
  });
}

export default {
  name: "ContactUs",

  components: { BaseButton },

  data: function() {
    return {
      /**
       * @desc valid form value
       * @author Hamza Sweid
       * @task #BACT-180
       * */
      valid: true,
      /**
       * @author Hamza Sweid
       * @task #BACT-180
       * */
      emailRules: [
        (v) => !!v || this.$t("required.email"),
        (v) => /.+@.+/.test(v) || this.$t("required.validEmail")
      ],
      /**
       * @author Hamza Sweid
       * @task #BACT-180
       * */
      nameRules: [(v) => !!v || this.$t("required.name")],
      /**
       * @author Hamza Sweid
       * @task #BACT-180
       * */
      noteRules: [(v) => !!v || this.$t("required.text")],
      /**
       * @author Hamza Sweid
       * @task #BACT-180
       * */
      loading: false,
      /**
       *  @desc form value
       * @author Hamza Sweid
       * @task #BACT-180
       * */
      name: "",
      email: "",
      note: ""
    };
  },

  methods: {
    ...mapActions("ContactUs", ["sendMsg"]),

    /**
     * @desc sendMsg function
     * @author Hamza Sweid
     * @task #BACT-180
     * */
    sendMsgMethod() {
      if (!this.$refs.form.validate())
        return notification(
          "public",
          "error",
          this.$t("form.checkMissingField"),
          4000
        );
      this.loading = true;
      this.sendMsg({
        name: this.name,
        email: this.email,
        note: this.note
      })
        .then(() => {
          this.loading = false;
          this.clear();
        })
        .catch(() => (this.loading = false));
    },
    /**
     * @desc clear function
     * @author Hamza Sweid
     * @task #BACT-180
     * */
    clear() {
      this.$refs.form.reset()
    }
  }
};
</script>

<style lang="scss" scoped>
.about-us {
  :lang(ar) {
    direction: ltr;
  }

  padding-top: 5rem;
  p {
    font-size: 18px;
  }
  ul {
    margin-top: 2rem;
    padding-left: 0;
  }
}
</style>
